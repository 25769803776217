<template>
  <div class="home-container">
    <div class="nav-container">
      <nav class="nav between">
        <div class="flex nav-l">
          <div class="logo" @click="toPages('/')">
            <img src="@/assets/img/home/logo.png" alt="" />
          </div>
          <li :class="[path === '/' ? 'active' : '']" @click="toPages('/')">
            首页
          </li>
          <li
            :class="[path === '/course' ? 'active' : '']"
            @click="toPages('/course')"
          >
            课件
          </li>
          <li
            :class="[path === '/games' ? 'active' : '']"
            @click="toPages('/games')"
          >
            游戏
          </li>
          <li
            :class="[path === '/forum' ? 'active' : '']"
            @click="toForum('/forum')"
          >
            论坛
          </li>
        </div>
        <div class=" nav-r flex">
          <el-tooltip
            placement="bottom"
            effect="light"
            :visible-arrow="tooltip"
          >
            <div slot="content" class="tooltip flex">
              <li v-if="mpQrcode.length > 0">
                <div>
                  <img :src="preSrc + copyright.pic1" alt="" />
                </div>
                <p class="ellipsis">{{copyright.word1}}</p>
              </li>
              <li v-if="mpQrcode.length > 1">
                <div>
                  <img :src="preSrc + copyright.pic2" alt="" />
                </div>
                <p class="ellipsis">{{copyright.word2}}</p>
              </li>
            </div>
            <div class="flex">
              <img src="@/assets/img/home/qrcode1.png" alt="" />
              <span>二维码</span>
            </div>
          </el-tooltip>
          <div
            class="flex profile"
            @click="toPages('/profile')"
          >
            <!-- <img src="@/assets/img/home/icon4.png" alt="" /> -->
            <!-- <img src="@/assets/img/home/profile.png" alt="" /> -->
            <span>进入工作台</span>
          </div>
        </div>
      </nav>
    </div>
    <router-view></router-view>
    <!-- 提示 -->
    <transition name="fade">
      <div
        class="mask-container"
        :class="[isShowMask ? 'mask-show' : 'mask-hide']"
        v-if="isShowMask"
      >
        <div class="mask-bg" @click="hideMask"></div>
        <div class="mask-con">
          <p class="title">提示</p>
          <p class="tips">系统检测到您还未登录，请先登录，登录后可查看</p>
          <button class="tip-btn btn" @click="hideMask">登录</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      tooltip: false,
      path: "/",
      preSrc: config.src,
      infos: {},
      mpQrcode: [],
      isShowMask: false,
      copyright: {}
    };
  },
  created() {
    let _self = this;
    let path = _self.$route.path;
    if (path.indexOf("/course") != -1) {
      _self.path = "/course";
    } else if (path.indexOf("/profile") != -1) {
      _self.path = "/profile";
    } else if (path.indexOf("/games") != -1) {
      _self.path = "/games";
    } else {
      _self.path = path;
    }
    // _self.setOriginPath(); //记录当前路由
    _self.initData();
    _self.getData();
  },
  methods: {
    initData() {
      let _self = this;
      _self.axios.post(config.url.home).then((res) => {
        if (res.code == 200) {
          _self.infos = res.data;
          _self.mpQrcode = res.data.mp_qrcode;
        } else if (res.code != 200 && res.code != 401) {
          _self.showTip("error", res.msg);
        }
      });
    },
    getData() {
      let _self = this;
      _self.axios.post(config.url.common).then((res) => {
        if (res.code == 200) {
          _self.copyright = res.data;
          localStorage.setItem('webTitle', res.data.web_name);
          localStorage.setItem('bbsUrl',res.data.bbs_url)
        } else if (res.code != 200 && res.code != 401) {
          _self.showTip("error", res.msg);
        }
      });
    },
    // 到论坛
    toForum() {
      const token = localStorage.getItem("token");
      if (!token) {
        this.isShowMask = true;
        return;
      }
      // window.open("http://192.168.0.110:3000/user/login?token=" + token);
      window.open(`${localStorage.getItem('bbsUrl')}user/login?token=${token}`)
      return;
    },
    // 隐藏弹窗
    hideMask() {
      let _self = this;
      _self.isShowMask = false;
    },
    toPages(path) {
      let _self = this;
      let token = localStorage.getItem("token");
      if (!token) {
        _self.isShowMask = true;
        return;
      }
      if (_self.$route.path == path) {
        return;
      }
      _self.path = path;
      _self.$router.replace(path).catch((err) => {});
    },
  },
  watch: {
    "$route.path": function(newVal, oldVal) {
      let _self = this;
      let path = newVal;
      let token = localStorage.getItem("token");
      // 这里做模糊匹配，展示导航下的每个页面导航都选中
      if (path.indexOf("/course") != -1) {
        _self.path = "/course";
      } else if (path.indexOf("/profile") != -1) {
        _self.path = "/profile";
      } else if (path.indexOf("/games") != -1) {
        _self.path = "/games";
      } else {
        _self.path = path;
      }
      _self.initData();
      _self.getData();
    },
  },
  //
};
</script>
<style lang="scss" scoped>
.home-container {
  background: #fafafa;
}
.nav-container {
  width: 100%;
  max-width: 1920px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  // overflow-x: auto;
  .nav {
    font-size: 20px;
    width: 1200px;
    margin: 0 auto;
    .nav-l {
      width: 800px;
    }
    li {
      width: 40px;
      color: $font-color-main;
      cursor: pointer;
      padding: 26px 0;
      border-bottom: 2px solid transparent;
      margin-right: 90px;
      box-sizing: border-box;
    }
    .logo {
      width: 80px;
      cursor: pointer;
      margin-right: 80px;;
      img {
        width: 80px;
        height: 80px;
        object-fit: contain;
      }
    }
    .active {
      color: $color-main;
      border-bottom: 2px solid $color-main;
    }
    .act {
      color: $color-main;
    }
    .nav-r {
      width: 400px;
      color: #818181;
      > div {
        margin-left: 50px;
        cursor: pointer;
        img {
          // width: 20px;
          // height: 20px;
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
        span {
          font-size: 16px;
        }
      }

      .profile {
        padding: 8px 16px;
        box-sizing: content-box;
        border: 1px solid $color-main;
        border-radius: 5px;
        color: $color-main;
        img {
          width: 17px;
          height: 19px;
        }
      }
    }
  }
}

.tooltip {
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 10px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.16);
  li {
    margin-right: 40px;
    div {
      width: 140px;
      height: 140px;
      border: 1px solid #dfdfdf;
      border-radius: 10px;
      padding: 10px;
      box-sizing: border-box;
      img {
        width: 100%;
      }
    }
    p {
      font-size: 14px;
      color: $font-color-9f;
      width: 100%;
      text-align: center;
      margin-top: 10px;
    }
  }

  li:last-of-type {
    margin-right: 0;
  }
}

.el-tooltip__popper.is-light[x-placement^="bottom"] .popper__arrow {
  border-bottom-color: #fff;
}

// 弹窗
.mask-container {
  .title {
    font-size: 30px;
    width: 100%;
    text-align: center;
  }
  .mask-bg {
    background: rgba(0, 0, 0, 0.5);
  }
  .mask-con {
    padding: 30px;
    box-sizing: border-box;
    border-radius: $border-radius-main;
    .tips {
      margin: 60px auto 70px;
      color: $font-color-51;
      font-size: 20px;
      text-align: center;
    }
    .tips::before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url("../assets/img/common/warn.png") center center no-repeat;
      background-size: 100%;
      margin-right: 10px;
    }
    .tip-btn {
      width: 260px;
      margin: 0 auto;
    }
  }
}
</style>
